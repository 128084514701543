import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import { addStyle, getRestProps } from '../utils';
import { context as VirtualRowsContext } from './useVirtualRows';
import { createVNode } from 'intact';
import { cx } from '@emotion/css';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const { children, className, tagName, style } = this.get();
    const { k } = this.config;
    
    return _$cc(VirtualRowsContext.Consumer, {
        'children': ({ translateY, disabled }) => {
            const classNameObj = {
                [`${k}-virtual-wrapper`]: !disabled,
                [className]: className,
            }
        
            const _style = !disabled ? { transform: `translateY(${translateY}px)` } : {};
            
            return createVNode(tagName || 'div', {
                ...getRestProps(this),
                className: cx(classNameObj),
                style: addStyle(style, _style)
            }, children);
        }
    });
};