import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import { getRestProps } from '../utils';
import { VirtualListContainer } from './container';
import { VirtualListWrapper } from './wrapper';
import { VirtualListRows } from './rows';
import { VirtualListPhantom } from './phantom';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const { children, disabled } = this.get();
    
    if (disabled) {
        return children;
    }
    return _$cc(VirtualListContainer, {
        ...getRestProps(this),
        'children': [
            _$cc(VirtualListPhantom),
            _$cc(VirtualListWrapper, {
                'children': (
                    _$cc(VirtualListRows, {
                        'children': children
                    })
                )
            })
        ]
    });
};