import {
    createElementVNode as _$ce,
    map as _$ma,
    className as _$cn,
    createUnknownComponentVNode as _$cc,
    createVNode as _$cv,
    noop as _$no,
    extend as _$ex,
    EMPTY_OBJ as _$em,
} from 'intact';
import {eachChildren} from '../utils';
import {makeStyles} from './styles';
import {isStringOrNumber, isNull, isObject, EMPTY_OBJ} from 'intact-shared';
import {getClassAndStyleForFixed} from './useFixedColumns';
import {Affix} from '../affix';
import {TableRow} from './row';
import {Checkbox} from '../checkbox';
import {context as GroupContext} from './useGroup';
import {context as SortableContext} from './useSortable';
import {Spin} from '../spin';
import {Transition, TransitionGroup, createComponentVNode} from 'intact';
import {_$} from '../../i18n';
import {context as TableContext} from './useColumns';
import {expandAnimationCallbacks} from '../utils';
import {Tooltip} from '../tooltip';
import {AllCheckedStatus} from './useChecked';
import {context as ResizableContext} from './useResizable';
import {context as FixedColumnsContext} from './useFixedColumns';
import {Pagination} from '../pagination';
import {VirtualListContainer, VirtualListWrapper, VirtualListRows, VirtualListPhantom} from '../virtualList';

var _$tmp0 = {
    'width': '40'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {
        data, children, className, fixHeader,
        checkType, rowKey, type, stripe,
        rowClassName, group, sort, loading,
        merge, childrenKey, indent, tooltipPosition,
        tooltipContainer, showIndeterminate, resizable,
        draggable, animation: _animation, hideHeader,
        pagination, fixFooter, virtual, spreadArrowIndex
    } = this.get();
    const animation = !Array.isArray(_animation) ? [_animation, _animation] : _animation;
    const {columns, cols, maxRows, maxCols} = this.columns.getData();
    const {lastCellKey, lastCellStyle} = this.columns.getStyleForLastColumn();
    const {scrollPosition, hasFixed, getHasFixedLeft, offsetMap} = this.fixedColumns;
    const {scrollRef} = this.scroll;
    const {stickHeader, excludeStickHeader, elementRef, headRef} = this.stickyHeader;
    const { k } = this.config;
    const classNameObj = {
        [`${k}-table`]: true,
        [`${k}-fix-header`]: fixHeader,
        [`${k}-fix-footer`]: fixFooter,
        [`${k}-scroll-${scrollPosition.value}`]: scrollPosition.value,
        [`${k}-fix-columns`]: hasFixed.value,
        [`${k}-${type}`]: type && type !== 'default',
        [`${k}-stripe`]: stripe,
        [`${k}-with-expand`]: $blocks.expand,
        [className]: className,
        [makeStyles(k)]: true,
    };
    const style = isStringOrNumber(fixHeader) ? 
        {maxHeight: `${fixHeader}px`} :
        null;
    
    const {getWidth, tableRef, tableWidth} = this.width;
    const {start: onStart} = this.resizable;
    
    const colgroup = (
        _$ce(2, 'colgroup', [
            (checkType !== 'none') ?
                _$ce(2, 'col', null, 1 /* HasInvalidChildren */, null, _$tmp0) :
                undefined,
            _$ma(cols, function($value, $key) {
                return _$ce(2, 'col', null, 1 /* HasInvalidChildren */, _$cn({
                    [`${k}-hidden`]: $value.hidden,
                    [$value.className]: $value.className
                }), {
                    'width': $value.width,
                    'style': {width: getWidth($value.key)}
                });
            }, $this)
        ], 0 /* UnknownChildren */)
    );
    const hasData = data && data.length;
    
    const hasFixedLeft = getHasFixedLeft();
    const {getAllCheckedStatus, toggleCheckedAll, getAllStatus, onChangeChecked} = this.checked;
    const allCheckedStatus = getAllCheckedStatus(); 
    const thead = hideHeader ? null : (
        _$cc(TableContext.Provider, {
            'value': {checkType, lastCellKey, lastCellStyle},
            'children': (
                _$cc(GroupContext.Provider, {
                    'value': {group, onChange: this.onChangeGroup},
                    'children': (
                        _$cc(SortableContext.Provider, {
                            'value': {sort, onChange: this.sortable.onChange},
                            'children': (
                                _$cc(ResizableContext.Provider, {
                                    'value': {resizable, onStart},
                                    'children': (
                                        _$cc(FixedColumnsContext.Provider, {
                                            'value': offsetMap.value,
                                            'children': (
                                                _$ce(2, 'thead', (
                                                    _$ma(columns, function($value, $key) {
                                                        return _$ce(2, 'tr', (() => {
                                                            const ths = [
                                                                ($key === 0 && checkType !== 'none') ?
                                                                    _$cv('th', {
                                                                        ...getClassAndStyleForFixed({
                                                                            fixed: hasFixedLeft ? 'left' : false,
                                                                        }, 0, k),
                                                                        'rowspan': maxRows,
                                                                        'key': '$checktype'
                                                                    }, (
                                                                        (checkType === 'checkbox') ?
                                                                            _$cc(Checkbox, {
                                                                                'value': allCheckedStatus === AllCheckedStatus.All,
                                                                                'indeterminate': showIndeterminate && allCheckedStatus == AllCheckedStatus.Indeterminate,
                                                                                'ev-$change:value': toggleCheckedAll,
                                                                                'disabled': !hasData
                                                                            }) :
                                                                            undefined
                                                                    )) :
                                                                    undefined,
                                                                $value
                                                            ]
                                                        
                                                            return animation[1] ?
                                                                _$cc(TransitionGroup, {
                                                                    'name': 'k-fade-expand',
                                                                    'move': false,
                                                                    'children': ths
                                                                }):
                                                                ths;
                                                        })(), 0 /* UnknownChildren */);
                                                    }, $this)
                                                ), 4 /* HasNonKeyedChildren */)
                                            )
                                        })
                                    )
                                })
                            )
                        })
                    )
                })
            )
        })
    );
    
    const {getAllKeys} = this.disableRow;
    const {getGrid} = this.merge;
    const allStatus = getAllStatus();
    const allKeys = getAllKeys();
    const colCount = maxCols + (checkType !== 'none' ? 1 : 0);
    const {isExpanded} = this.expandable;
    const {isSelected} = this.selected;
    const {loopData, isSpreaded, toggleSpreadRow} = this.tree;
    const {onRowDragStart, onRowDragOver, onRowDragEnd, draggingKey} = this.draggable;
    const tbody = (
        _$cc(VirtualListWrapper, {
            'tagName': 'tbody',
            'children': !hasData ? 
                _$ce(2, 'tr', (
                    _$ce(2, 'td', (
                        (
                            (_$blocks['empty'] = function($super) {
                                return _$('暂无数据');
                            }),
                            (__$blocks['empty'] = function($super, data) {
                                var block = $blocks['empty'];
                                var callBlock = function() {
                                    return _$blocks['empty'].call($this, $super, data);
                                };
                                return block ?
                                    block.call($this, callBlock, data) :
                                    callBlock();
                            }),
                            __$blocks['empty'](_$no)
                        )
                    ), 0 /* UnknownChildren */, _$cn(`${k}-table-empty`), {
                        'colspan': colCount
                    })
                ), 2 /* HasVNodeChildren */, null, null, 'table-empty'):
                (() => {
                    const rows = [];
                    loopData((value, index, level, hidden) => {
                        // don't render if row is hidden
                        if (hidden) return hidden;
            
                        const status = allStatus[index];
                        const key = allKeys[index];
                        const spreaded = isSpreaded(key);
                        const hasChildren = !!childrenKey && Array.isArray(value[childrenKey]);
            
                        const indentSize = indent ? indent * level : 0; 
                        let row = _$cc(TableRow, {
                            'key': key,
                            'cols': cols,
                            'data': value,
                            'loaded': value.loaded,
                            'checkType': checkType,
                            'hasFixedLeft': hasFixedLeft,
                            'onClick': this.clickRow,
                            'checked': status.checked,
                            'indeterminate': status.indeterminate,
                            'index': index,
                            'disabled': status.disabled,
                            'allDisabled': status.allDisabled,
                            'className': _$cn(rowClassName && rowClassName(value, index, key)),
                            'merge': merge,
                            'grid': getGrid(),
                            'onChangeChecked': onChangeChecked,
                            'selected': isSelected(key),
                            'spreaded': spreaded,
                            'hasChildren': hasChildren,
                            'indent': indentSize,
                            'onToggleSpreadRow': toggleSpreadRow,
                            'onBeforeUnmount': this.resetRowStatus.onRowBeforeUnmount,
                            'offsetMap': offsetMap.value,
                            'animation': animation[1],
                            'spreadArrowIndex': spreadArrowIndex,
                            'draggable': draggable,
                            'draggingKey': draggingKey.value,
                            'onRowDragStart': onRowDragStart,
                            'onRowDragEnd': onRowDragEnd,
                            'onRowDragOver': onRowDragOver
                        }, key)
            
                        if ($blocks.tooltip) {
                            const content = (
                                (_$blocks['tooltip'] = function($super) {
                                    return null;
                                }),
                                (__$blocks['tooltip'] = function($super, data) {
                                    var block = $blocks['tooltip'];
                                    var callBlock = function() {
                                        return _$blocks['tooltip'].call($this, $super, data);
                                    };
                                    return block ?
                                        block.call($this, callBlock, data) :
                                        callBlock();
                                }),
                                __$blocks['tooltip'](_$no, [value, index])
                            );
                            row = (
                                _$cc(Tooltip, {
                                    'key': key,
                                    'position': tooltipPosition,
                                    'container': tooltipContainer,
                                    'children': (
                                        row
                                    ),
                                    '$blocks': function($blocks) {
                                        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
                                        return (
                                            (
                                                (_$blocks['content'] = function($super) {
                                                    return content;
                                                }),
                                                (__$blocks['content'] = function($super, data) {
                                                    var block = $blocks['content'];
                                                    var callBlock = function() {
                                                        return _$blocks['content'].call($this, $super, data);
                                                    };
                                                    return block ?
                                                        block.call($this, callBlock, data) :
                                                        callBlock();
                                                })
                                            ),
                                            __$blocks
                                        );
                                    }.call($this, _$em)
                                }, key)
                            );
                        }
                        rows.push(row);
                        if ($blocks.expand) {
                            const expanded = isExpanded(key);
                            rows.push(
                                _$ce(2, 'tr', (
                                    _$ce(2, 'td', (
                                        _$cc(Transition, {
                                            ...expandAnimationCallbacks,
                                            'children': (
                                                (expanded) ?
                                                    _$ce(2, 'div', (
                                                        (
                                                            (_$blocks['expand'] = function($super) {
                                                                return null;
                                                            }),
                                                            (__$blocks['expand'] = function($super, data) {
                                                                var block = $blocks['expand'];
                                                                var callBlock = function() {
                                                                    return _$blocks['expand'].call($this, $super, data);
                                                                };
                                                                return block ?
                                                                    block.call($this, callBlock, data) :
                                                                    callBlock();
                                                            }),
                                                            __$blocks['expand'](_$no, [value, index])
                                                        )
                                                    ), 0 /* UnknownChildren */, _$cn(`${k}-table-expand`)) :
                                                    undefined
                                            )
                                        })
                                    ), 2 /* HasVNodeChildren */, null, {
                                        'colspan': colCount
                                    })
                                ), 2 /* HasVNodeChildren */, _$cn({[`${k}-expand`]: true, [`${k}-expanded`]: expanded}), null, `${key}.$expand`)
                            );
                        }
            
                         return hidden || !spreaded;
                    });
            
                    return animation[0] && !virtual?
                        _$cc(TransitionGroup, {
                            'name': 'k-fade-in-left',
                            'move': !draggingKey.value,
                            'children': rows
                        }): 
                        _$cc(VirtualListRows, {
                            'children': rows
                        });
                })()
        })
    );
    
    let tfooter = null;
    if ($blocks.footer) {
        tfooter = _$ce(2, 'tfoot', (
            _$ce(2, 'tr', (
                _$ce(2, 'td', (
                    (
                        (_$blocks['footer'] = function($super) {
                            return null;
                        }),
                        (__$blocks['footer'] = function($super, data) {
                            var block = $blocks['footer'];
                            var callBlock = function() {
                                return _$blocks['footer'].call($this, $super, data);
                            };
                            return block ?
                                block.call($this, callBlock, data) :
                                callBlock();
                        }),
                        __$blocks['footer'](_$no)
                    )
                ), 0 /* UnknownChildren */, _$cn(`${k}-table-footer`), {
                    'colspan': colCount
                })
            ), 2 /* HasVNodeChildren */, null, null, 'table-footer')
        ), 2 /* HasVNodeChildren */)
    }
    
    const tableWidthPx = tableWidth.value ? `${tableWidth.value}px` : null;
    const {
        shouldStickScrollbar,
        stick: stickScrollbar,
        style: stickScrollbarStyle,
        scrollbarRef,
        onScroll: onScrollbarScroll,
        tableActualWidth,
    } = this.stickyScrollbar;
    const {
        value: pageValue,
        limit: pageLimit,
        onChange: onPageChange,
        paginationRef,
    } = this.pagination;
    
    return _$ce(2, 'div', [
        _$cc(VirtualListContainer, {
            'className': _$cn(`${k}-table-wrapper`),
            'disabled': !virtual,
            'style': style,
            'ref': scrollRef,
            'children': [
                _$cc(VirtualListPhantom),
                (!isNull(stickHeader.value) || fixHeader) ?
                    _$cc(Affix, {
                        'top': stickHeader.value,
                        'exclude': excludeStickHeader,
                        'className': _$cn(`${k}-table-affix-header`),
                        'disabled': !!fixHeader,
                        'children': (
                            _$ce(2, 'table', [
                                colgroup,
                                thead
                            ], 0 /* UnknownChildren */, null, {
                                'style': {width: tableWidthPx}
                            }, null, headRef)
                        )
                    }) :
                    undefined,
                _$ce(2, 'table', [
                    colgroup,
                    (isNull(stickHeader.value) && !fixHeader) ?
                        thead :
                        undefined,
                    tbody,
                    ($blocks.footer) ?
                        tfooter :
                        undefined
                ], 0 /* UnknownChildren */, null, {
                    'style': {width: tableWidthPx}
                }, null, tableRef)
            ]
        }, null, scrollRef),
        (pagination) ?
            _$cc(Pagination, {
                'ref': paginationRef,
                'total': data ? data.length : 0,
                'size': 'small',
                ...(isObject(pagination) ? pagination : EMPTY_OBJ),
                'value': pageValue.value,
                'limit': pageLimit.value,
                'ev-change': onPageChange
            }, null, paginationRef) :
            undefined,
        _$cc(Transition, {
            'name': 'k-fade',
            'children': (
                (loading) ?
                    _$cc(Spin, {
                        'overlay': true,
                        'size': 'small',
                        'className': _$cn(`${k}-table-spin`)
                    }) :
                    undefined
            )
        }),
        (!isNull(stickScrollbar.value)) ?
            _$cc(Affix, {
                'bottom': stickScrollbar.value,
                'shouldFix': shouldStickScrollbar,
                'style': stickScrollbarStyle.value,
                'children': (
                    _$ce(2, 'div', (
                        _$ce(2, 'div', null, 1 /* HasInvalidChildren */, _$cn(`${k}-table-scrollbar-inner`), {
                            'style': {width: tableActualWidth.value}
                        })
                    ), 2 /* HasVNodeChildren */, _$cn(`${k}-table-scrollbar`), {
                        'ev-scroll': onScrollbarScroll
                    }, null, scrollbarRef)
                )
            }) :
            undefined
    ], 0 /* UnknownChildren */, _$cn(classNameObj), null, null, elementRef);
};