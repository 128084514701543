import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
} from 'intact';
import { VirtualList, Button } from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$cc(Button, {
            'ev-click': this.removeItems,
            'children': '删除前5项'
        }),
        _$cc(Button, {
            'ev-click': this.removeLastItems,
            'children': '删除后5项'
        }),
        _$cc(VirtualList, {
            'style': 'height: 450px',
            'children': (
                _$ma(this.get('data'), function($value, $key) {
                    return _$ce(2, 'div', $value.label, 0 /* UnknownChildren */, 'fixed-height-item');
                }, $this)
            )
        })
    ], 4 /* HasNonKeyedChildren */);
};