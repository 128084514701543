export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props {
    data: any[]
    variableHeightData: any[]
}

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            data: [],
            variableHeightData: [],
        } as Props;
    }

    init() {
        const arr = [];
        const variableHeightData = [];
        for (let index = 0; index < 10000; index++) {
            arr.push({
                value: index,
                label: `测试${index}`
            });
            const repeatPart = '行内容'.repeat(Math.floor(Math.random() * 5) + 1);
            variableHeightData.push({ value: index, label: `不定高度项 ${index}\n${repeatPart}` });
        }
        this.set({data: arr, variableHeightData});
    }
}