import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
} from 'intact';
import {Select, Option} from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(Select, {
            'virtual': true,
            'children': (
                _$ma(this.get('data'), function($value, $key) {
                    return _$cc(Option, {
                        'value': $value.value,
                        'children': $value.label
                    });
                }, $this)
            ),
            'ev-$model:value': function($v) {
                $this.set('day', $v);
            },
            'value': $this.get('day')
        })
    ), 2 /* HasVNodeChildren */);
};