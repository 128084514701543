export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props {
    data: any[]
    variableHeightData: any[]
}
import {range, bind} from 'kpc/components/utils';
export default class extends Component {
    static template = template;
    static defaults() {
        return {
            data: [],
            variableHeightData: []
        } as Props;
    }
    init() {
        const arr = [];
        for (let index = 0; index < 10000; index++) {
            arr.push({
                a: `Cell ${index}-1`,
                b: `Cell ${index}-2`
            });
        }
        this.set({data: arr});

        const generateTreeData = (count: number) => {
            const result = [];
            
            for (let i = 0; i < count; i++) {
                const hasChildren = Math.random() > 0.5;
                const node: any = {
                    name: `Node-${i}`,
                    key: `node-${i}`,
                    size: Math.floor(Math.random() * 100),
                };

                if (hasChildren) {
                    node.children = Array.from({ length: 2 }, (_, j) => ({
                        name: `Node-${i}-${j}`,
                        key: `node-${i}-${j}`,
                        size: Math.floor(Math.random() * 100),
                        children: Math.random() > 0.5 ? [
                            {
                                name: `Node-${i}-${j}-0`,
                                key: `node-${i}-${j}-0`,
                                size: Math.floor(Math.random() * 100),
                            }
                        ] : undefined
                    }));
                }

                result.push(node);
            }

            return result;
        };

        const data = generateTreeData(10000);
        this.set({ variableHeightData: data });
    }

    @bind
    scrollToRowByKey() {
        this.refs.table.scrollToRowByKey('Node-400');
    }
}