export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

import {bind} from 'kpc/components/utils';
interface Props {
    data: any[]
}

export default class extends Component<Props> {
    static template = template;

    static defaults() {
        return {
            data: [],
        } as Props
    }

    init() {
        const newData = [];
        const variableHeightData = [];
        for (let index = 0; index < 10000; index++) {
            newData.push({
                value: index,
                label: `测试${index}`
            });
        }
        this.set({data: newData});
    }

    @bind
    removeItems() {
        const data = this.get('data').slice();
        data.splice(0, 5);
        this.set('data', data);
    }

    @bind
    removeLastItems() {
        const data = this.get('data').slice();
        data.splice(data.length - 5);
        this.set('data', data);
    }
}