import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {Table, TableColumn} from 'kpc';

var _$tmp0 = {
    'key': 'a',
    'title': 'Title 1',
    'minWidth': 200
};
var _$tmp1 = {
    'key': 'b',
    'title': 'Title 2',
    'minWidth': 300
};
var _$tmp2 = {
    'key': 'name',
    'title': 'Title 1',
    'minWidth': 200
};
var _$tmp3 = {
    'key': 'size',
    'title': 'Title 2',
    'minWidth': 300
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$refs = this.refs;
    var _$ref_table = function(i) {_$refs['table'] = i};
    
    return _$ce(2, 'div', [
        _$ce(2, 'h4', '表格', 16 /* HasTextChildren */),
        _$cc(Table, {
            'data': this.get('data'),
            'virtual': true,
            'fixHeader': '400',
            'children': [
                _$cc(TableColumn, _$tmp0, 'a'),
                _$cc(TableColumn, _$tmp1, 'b')
            ]
        }),
        _$ce(2, 'h4', '树形表格', 16 /* HasTextChildren */),
        _$cc(Table, {
            'ref': _$ref_table,
            'data': this.get('variableHeightData'),
            'virtual': true,
            'rowKey': data => data.name,
            'fixHeader': '400',
            'children': [
                _$cc(TableColumn, _$tmp2, 'name'),
                _$cc(TableColumn, _$tmp3, 'size')
            ]
        }, null, _$ref_table)
    ], 4 /* HasNonKeyedChildren */);
};