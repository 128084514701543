export {default as data} from './index.json';
import {Component} from 'intact';
import template from './index.vdt';
import './index.styl'; 

interface Props {
    data: any[]
}

export default class extends Component {
    static template = template;

    static defaults() {
        return {
            data: [],
        } as Props;
    }

    init() {
        const arr = [];
        for (let index = 0; index < 10000; index++) {
            arr.push({
                value: index,
                label: `测试${index}`
            });
        }
        this.set({data: arr});
    }
}