import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import { createFragment, createVNode } from 'intact';
import { isNullOrUndefined } from 'intact-shared';
import { context as VirtualRowsContext } from './useVirtualRows';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    let { tagName, children } = this.get();
    const rows = this.rows;
    
    return _$cc(VirtualRowsContext.Consumer, {
        'children': ({ notifyRows, startIndex, length, disabled }) => {
            if (!disabled) {
                notifyRows(rows.value);
                children = rows.value.slice(startIndex, startIndex + length);
            }
        
            if (isNullOrUndefined(tagName)) {
                if (!children.length) return;
                return createFragment(children, 8 /* ChildrenTypes.HasKeyedChildren */);
            }
            return createVNode(tagName, null, children);
        }
    });
};