import {
    createUnknownComponentVNode as _$cc,
    createVNode as _$cv,
    className as _$cn,
} from 'intact';
import { addStyle, getRestProps } from '../utils';
import { makeContainerStyles } from './styles';
import { context as VirtualRowsContext } from './useVirtualRows';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const { children, className, disabled, ref } = this.get();
    const { k } = this.config;
    
    const { notifyRows, startIndex, length, getTotalHeight, translateY } = this.virtualRows;
    
    const classNameObj = {
        [`${k}-virtual`]: !disabled,
        [`${k}-virtual-container`]: !disabled,
        [makeContainerStyles(k)]: !disabled,
        [className]: className,
    }
    
    return _$cc(VirtualRowsContext.Provider, {
        'value': {
            disabled,
            notifyRows,
            getTotalHeight,
            startIndex: startIndex.value,
            length: length.value,
            translateY: translateY.value,
        },
        'children': (
            _$cv('div', {
                ...getRestProps(this),
                'className': _$cn(classNameObj),
                'ref': ref
            }, children)
        )
    });
};