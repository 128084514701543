import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
} from 'intact';
import { VirtualList } from 'kpc';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', [
        _$ce(2, 'h3', '1. 定高元素', 16 /* HasTextChildren */),
        _$cc(VirtualList, {
            'style': 'height: 450px',
            'children': (
                _$ma(this.get('data'), function($value, $key) {
                    return _$ce(2, 'div', $value.label, 0 /* UnknownChildren */, 'fixed-height-item');
                }, $this)
            )
        }),
        _$ce(2, 'h3', '2. 不定高元素', 16 /* HasTextChildren */),
        _$cc(VirtualList, {
            'style': 'width: 200px; height: 450px;',
            'children': (
                _$ma(this.get('variableHeightData'), function($value, $key) {
                    return _$ce(2, 'div', $value.label, 0 /* UnknownChildren */, 'variable-height-item');
                }, $this)
            )
        })
    ], 4 /* HasNonKeyedChildren */);
};