import {
    createVNode as _$cv,
    className as _$cn,
    createElementVNode as _$ce,
    noop as _$no,
    createUnknownComponentVNode as _$cc,
} from 'intact';
import {makeGroupStyles} from './styles';
import {getRestProps} from '../utils';
import { VirtualList } from '../virtualList';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {children, label, className} = this.get();
    const {card, virtual} = this.select.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-select-group`]: true,
        [className]: className,
        [makeGroupStyles(k)]: true,
    }
    
    return _$cv('div', {
        ...getRestProps(this),
        'className': _$cn(classNameObj)
    }, [
        (!card) ?
            _$ce(2, 'div', (
                (
                    (_$blocks['label'] = function($super) {
                        return label;
                    }),
                    (__$blocks['label'] = function($super, data) {
                        var block = $blocks['label'];
                        var callBlock = function() {
                            return _$blocks['label'].call($this, $super, data);
                        };
                        return block ?
                            block.call($this, callBlock, data) :
                            callBlock();
                    }),
                    __$blocks['label'](_$no)
                )
            ), 0 /* UnknownChildren */, _$cn(`${k}-select-group-label`)) :
            undefined,
        _$cc(VirtualList, {
            'disabled': !virtual,
            'children': children
        })
    ]);
};