import {
    createElementVNode as _$ce,
    createUnknownComponentVNode as _$cc,
    map as _$ma,
} from 'intact';
import { VirtualListContainer, VirtualListWrapper, VirtualListPhantom, VirtualListRows } from 'kpc';

var _$tmp0 = {
    'style': 'height: 460px'
};

export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    return _$ce(2, 'div', (
        _$cc(VirtualListContainer, {
            'children': [
                _$cc(VirtualListPhantom),
                _$cc(VirtualListWrapper, {
                    'tagName': 'ul',
                    'children': (
                        _$cc(VirtualListRows, {
                            'children': (
                                _$ma(this.get('data'), function($value, $key) {
                                    return _$ce(2, 'li', $value.label, 0 /* UnknownChildren */, 'fixed-height-item');
                                }, $this)
                            )
                        })
                    )
                })
            ]
        })
    ), 2 /* HasVNodeChildren */, null, _$tmp0);
};