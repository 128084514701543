import {
    superCall as _$su,
    extend as _$ex,
    EMPTY_OBJ as _$em,
    createUnknownComponentVNode as _$cc,
    className as _$cn,
    noop as _$no,
} from 'intact';
import {SelectMenu} from './menu';
import {isEmptyChildren} from '../utils';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    var _$blocks = {};
    var __$blocks = {};
    
    const {className, children, autoDisableArrow, disabled, multiple, value, virtual} = this.get();
    const {getCreatedVNode, filter} = this.filterable;
    const options = filter(children);
    const allShowedValues = this.getAllShowedValues(options);
    
    const isDisableArrow =  disabled ||
        autoDisableArrow && (
            isEmptyChildren(options) ||
            allShowedValues.length === 1 &&
            !multiple &&
            allShowedValues[0] === value
        );
    
    return _$su.call($this, {
        'isDisableArrow': isDisableArrow
    }, function($blocks) {
        var _$blocks = {}, __$blocks = _$ex({}, $blocks);
        return (
            (
                (_$blocks['base-menu'] = function($super) {
                    return (
                        _$cc(SelectMenu, {
                            'className': _$cn(className),
                            'values': allShowedValues,
                            'children': (
                                (
                                    (
                                        (_$blocks['menu'] = function($super) {
                                            return [
                                                getCreatedVNode(options),
                                                options
                                            ];
                                        }),
                                        (__$blocks['menu'] = function($super, data) {
                                            var block = $blocks['menu'];
                                            var callBlock = function() {
                                                return _$blocks['menu'].call($this, $super, data);
                                            };
                                            return block ?
                                                block.call($this, callBlock, data) :
                                                callBlock();
                                        }),
                                        __$blocks['menu'](_$no)
                                    )
                                )
                            )
                        })
                    );
                }),
                (__$blocks['base-menu'] = function($super, data) {
                    var block = $blocks['base-menu'];
                    var callBlock = function() {
                        return _$blocks['base-menu'].call($this, $super, data);
                    };
                    return block ?
                        block.call($this, callBlock, data) :
                        callBlock();
                })
            ),
            __$blocks
        );
    }.call($this, $blocks), $__proto__);
};