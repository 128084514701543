import {
    createUnknownComponentVNode as _$cc,
} from 'intact';
import { getRestProps } from '../utils';
import { context as VirtualRowsContext } from './useVirtualRows';
import { cx } from '@emotion/css';
import { createVNode } from 'intact';
import { makePhantomStyles } from './styles';


export default function($props, $blocks, $__proto__) {
    $blocks || ($blocks = {});
    $props || ($props = {});
    var $this = this;
    
    const { className, tagName } = this.get();
    const { k } = this.config;
    
    const classNameObj = {
        [`${k}-virtual-phantom`]: true,
        [makePhantomStyles()]: true,
        [className]: className,
    }
    
    return _$cc(VirtualRowsContext.Consumer, {
        'children': ({ getTotalHeight, disabled }) => {
            if (disabled) return;
            
            return createVNode(tagName || 'div', {
                ...getRestProps(this),
                className: cx(classNameObj),
                style: {
                    height: getTotalHeight() + 'px', 
                },
            });
        }
    });
};